import React from 'react';
import PropTypes from 'prop-types';

import {
  getMovieDetailsPageRoute,
  getSceneDetailsPageRoute,
} from '../../services/navigation/navigation.service.routes';
import { getPosterImage } from '../../services/images-service/images.service';
import { convertSecondsToTime, isToday } from '../../services/util-service/date.service';
import MovieBoxCoverStillDisplay from '../MovieBoxCoverStillDisplay/MovieBoxCoverStillDisplay';
import { getShowMeOptions, getSortByOptions, navigationOptions } from '../../constants/navigation';
import ShowMeSortByNavigation from '../ShowMeSortByNavigation/ShowMeSortByNavigation';
import ImageLoadingSkeleton from '../ImageLoadingSkeleton/ImageLoadingSkeleton';
import { isWeb, setContentScrolltop } from '../../services/util-service/util.service';
import { Portal } from '../Portal/Portal';
import BaseSectionHeader from '../BaseSectionHeader/BaseSectionHeader';

import TopTenNumber1 from '../../images/top-ten/TopTenNumber1.svg';
import TopTenNumber2 from '../../images/top-ten/TopTenNumber2.svg';
import TopTenNumber3 from '../../images/top-ten/TopTenNumber3.svg';
import TopTenNumber4 from '../../images/top-ten/TopTenNumber4.svg';
import TopTenNumber5 from '../../images/top-ten/TopTenNumber5.svg';
import TopTenNumber6 from '../../images/top-ten/TopTenNumber6.svg';
import TopTenNumber7 from '../../images/top-ten/TopTenNumber7.svg';
import TopTenNumber8 from '../../images/top-ten/TopTenNumber8.svg';
import TopTenNumber9 from '../../images/top-ten/TopTenNumber9.svg';
import TopTenNumber10 from '../../images/top-ten/TopTenNumber10.svg';
import NewTodayEmblem from '../../images/NewTodayEmblem.svg';

import './MoviesScenesGrid.scss';

const movieNumberMarkimages = {
  0: TopTenNumber1,
  1: TopTenNumber2,
  2: TopTenNumber3,
  3: TopTenNumber4,
  4: TopTenNumber5,
  5: TopTenNumber6,
  6: TopTenNumber7,
  7: TopTenNumber8,
  8: TopTenNumber9,
  9: TopTenNumber10,
};

const MoviesScenesGrid = (props) => {
  const {
    data = [],
    loading,
    type = navigationOptions.scenes,
    sort,
    sortByOptions,
    error,
    hasGalleryImages,
    hideNavigation,
    hidePoster,
    showNumberIndicator,
    title,
    viewAllLink = {},
    subTitle,
    description,
    refProp,
  } = props;

  const activeSectionClassName = type === navigationOptions.scenes ? `Scenes ${hidePoster ? '' : 'IncreaseGridGap'}` : 'Movies';

  const renderNumberIndicator = (index) => {
    if (!showNumberIndicator) return null;
    const idx = isWeb() ? index + 1 : index; // on DESKTOP first movie is a poster/image
    const src = movieNumberMarkimages[idx];

    return (
      <img src={src} alt={`TopTen Number ${idx}`} loading="lazy" width="63" height="58" className="NumberIndicator" />
    );
  };

  const renderNewTodayIndicator = (publish_start) => {
    return isToday(publish_start) ? (
      <img src={NewTodayEmblem} alt="New today emblem" className="NewTodatSticker" width="85" height="85" />
    ) : null;
  };

  const renderMoviesScenes = () => {
    if (!Array.isArray(data)) return null;

    const videos = data.map((item, idx) => {
      let {
        movieId,
        title,
        titleNs,
        sample_video,
        startTimeSeconds = 0,
        endTimeSeconds = 0,
        cover_image,
        cover_images = [],
        streaming_movie = {},
        first_scene = {},
        publish_start,
        index,
        movie = {},
      } = item;

      let stars;
      let poster = [cover_image];
      let preview = sample_video;
      let previewStartTime = startTimeSeconds;
      let previewEndTime = endTimeSeconds;
      let sceneIndex = index;

      if (movie && Object.keys(movie).length !== 0) {
        movieId = movie.movieId;
      }

      if (streaming_movie && Object.keys(streaming_movie).length !== 0) {
        title = streaming_movie.title;
        titleNs = streaming_movie.titleNs;
        stars = streaming_movie.stars;
        poster = streaming_movie.images;
      }

      if (first_scene && Object.keys(first_scene).length !== 0) {
        cover_images = first_scene.cover_images || [];
        preview = first_scene.sample_video;
        stars = first_scene.stars;
        previewStartTime = first_scene.startTimeSeconds;
        previewEndTime = first_scene.endTimeSeconds;
        sceneIndex = first_scene.index;
      }

      const duration = convertSecondsToTime(previewEndTime - previewStartTime);
      const movieName = titleNs || title || '';
      const movieNavigationRoute = getMovieDetailsPageRoute(movieId, movieName);
      const sceneNavigationRoute = getSceneDetailsPageRoute(movieId, movieName, sceneIndex);
      const frontPoster = getPosterImage(poster);

      const view = (
        <MovieBoxCoverStillDisplay
          boxCoverUrl={frontPoster.url}
          alt={frontPoster.alt || movieName}
          duration={duration}
          endTimeSeconds={previewEndTime}
          heroStillUrl={cover_images[0]?.url}
          movieId={movieId}
          movieNavigationRoute={movieNavigationRoute}
          sceneNavigationRoute={sceneNavigationRoute}
          sampleVideoUrl={preview}
          stars={stars}
          startTimeSeconds={previewStartTime}
          title={movieName}
          key={movieId + '-' + sceneIndex + '-' + idx}
          renderIndicatorFn={() => renderNumberIndicator(idx)}
          renderStillOverlayFn={() => renderNewTodayIndicator(publish_start)}
          viewType={type}
          hidePoster={hidePoster}
        />
      );

      return view;
    });

    return <div className={`Grid ${activeSectionClassName}`}>{videos}</div>;
  };

  const onShowMeClick = (val) => {
    const { onShowMeClick } = props;
    if (onShowMeClick) onShowMeClick(val);
  };

  const onSortByClick = (val) => {
    const { onSortByClick } = props;
    if (onSortByClick) onSortByClick(val);
  };

  const renderPlaceholders = (itemsCount) => {
    const placeholders = [];

    for (let i = 0; i < itemsCount; i++) {
      placeholders.push(
        <div key={i}>
          <ImageLoadingSkeleton className="ImagePlaceholder" />
          <ImageLoadingSkeleton className="TextPlaceholder" />
        </div>,
      );
    }
    return <div className={`PlaceholdersGrid ${activeSectionClassName}`}>{placeholders}</div>;
  };

  const renderNavigation = () => {
    if (!isWeb() || hideNavigation) return null;
    let showMeItems = getShowMeOptions(hasGalleryImages);
    let sortByItems = getSortByOptions();

    if (sortByOptions) {
      sortByItems = sortByOptions;
    }

    return (
      <ShowMeSortByNavigation
        showMeItems={showMeItems}
        sortByItems={sortByItems}
        sortByInitialValue={sort}
        showMeInitialValue={type}
        onShowMeClick={onShowMeClick}
        onSortByClick={onSortByClick}
      />
    );
  };

  const renderMobileNavigation = () => {
    if (isWeb() || hideNavigation) return null;

    let renderItems = [
      <div
        key={navigationOptions.movies}
        className={`Item ${type === navigationOptions.movies ? 'Active' : ''}`}
        onClick={() => {
          onShowMeClick(navigationOptions.movies);
          setContentScrolltop();
        }}
      >
        {navigationOptions.movies}
      </div>,

      <div
        key={navigationOptions.scenes}
        className={`Item ${type === navigationOptions.scenes ? 'Active' : ''}`}
        onClick={() => {
          onShowMeClick(navigationOptions.scenes);
          setContentScrolltop();
        }}
      >
        {navigationOptions.scenes}
      </div>,
    ];

    if (hasGalleryImages) {
      renderItems.push(
        <div key={navigationOptions.photos} className="Item" onClick={() => onShowMeClick(navigationOptions.photos)}>
          {navigationOptions.photos}
        </div>,
      );
    }
    return <Portal>{renderItems}</Portal>;
  };

  const renderVideos = () => {
    if (loading) return renderPlaceholders(12);
    return renderMoviesScenes();
  };

  const renderTitle = () => {
    if (!title) return null;

    return (
      <BaseSectionHeader
        primaryText={title}
        secondaryLink={viewAllLink.url}
        secondaryText={viewAllLink.text}
      />
    );
  };

  const renderSubtitle = () => {
    if (isWeb() || !subTitle) return null;
    return <BaseSectionHeader primaryText={subTitle} className='PageSubTitle' />
  };

  const renderDescription = () => {
    if (!description) return null;
    return (
      <div className="Description">
        <span dangerouslySetInnerHTML={{ __html: description }}></span>
      </div>
    );
  };

  if (error) return null;

  return (
    <div className="MoviesScenesGrid" ref={refProp}>
      {renderTitle()}
      {renderDescription()}
      {renderSubtitle()}
      {renderNavigation()}
      {renderVideos()}
      {renderMobileNavigation()}
    </div>
  );
};

MoviesScenesGrid.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  type: PropTypes.string,
  sort: PropTypes.string,
  sortByOptions: PropTypes.array,
  title: PropTypes.string,
  viewAllLink: PropTypes.object,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  refProp: PropTypes.object,
  error: PropTypes.bool,
  hasGalleryImages: PropTypes.bool,
  hideNavigation: PropTypes.bool,
  hidePoster: PropTypes.bool,
  showNumberIndicator: PropTypes.bool,
};

MoviesScenesGrid.displayName = 'MoviesScenesGrid';

export default MoviesScenesGrid;
