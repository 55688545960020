import React from 'react';
import NewsletterSignUp from '../NewsletterSignUp/NewsletterSignUp.js';
import RenderHtmlContent from '../RenderHtmlContent/RenderHtmlContent';
import ModalContainer from '../ModalContainer/ModalContainer.js';
import SignIn from '../SignIn/SignIn.js';
import { useWLconfigContext } from '../../context/WLConfigContext.js';
import { useAuthContext } from '../../context/AuthContext.js';

import { ReactComponent as TwitterIcon } from '../../images/twitter_icon.svg';
import { ReactComponent as InstagramIcon } from '../../images/instagram_icon.svg';
import { links } from '../../links';

import ApplicationLogo from '../ApplicationLogo/ApplicationLogo';

import './Footer.scss';

function Footer() {
  const { wl_config = {} } = useWLconfigContext();
  const { userLoggedIn } = useAuthContext();

  return (
    <div className="Footer">
      <div className="DesktopLogoContainer">
        <ApplicationLogo notLink />
      </div>

      <NewsletterSignUp />

      <div className="FooterSignInContainer">
        {!userLoggedIn && (
          <>
            <span className="text-uppercase">Have an account?</span>{' '}
            <ModalContainer className='SignInModal' buttonText='SIGN IN'>
              <SignIn />
            </ModalContainer>
          </>
        )}
      </div>

      <div className="SocialIconsContainer">
        <a className="Icons TwitterLinkIcon" target="_blank" rel="noreferrer" href={links.twitter} aria-label='Twitter icon link'>
          <TwitterIcon />
        </a>
        <a className="Icons InstagramLinkIcon" target="_blank" rel="noreferrer" href={links.instagram} aria-label='Instagram icon link'>
          <InstagramIcon />
        </a>
      </div>

      <RenderHtmlContent htmlContent={wl_config} dataLabel="footer_links" />
      <RenderHtmlContent htmlContent={wl_config} dataLabel="footer_text" />
    </div>
  );
}

export default Footer;