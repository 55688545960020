import { createContext, useContext, useState, useEffect } from 'react';
import { getWhiteLabelConfig } from '../services/properties/properties.service';
import { getPlayerConfig } from '../services/player/player.service';
import { ROUTES } from '../services/navigation/navigation.routes';
import { env } from '../env';

// get site and player configuration data from Gandolf
// Actions -> General

const WLConfigContext = createContext();

const envs = {
  REACT_APP_ENV_GA_MEASUREMENT_ID: env.REACT_APP_ENV_GA_MEASUREMENT_ID || 'G-CVZ4RHQ130',
  REACT_APP_UNIVERSAL_GA_ID: env.REACT_APP_UNIVERSAL_GA_ID || 'G-ZGSEPJTQ7G',
  REACT_APP_WHITELABEL_GTM_ID: env.REACT_APP_WHITELABEL_GTM_ID || 'GTM-M7XV3FB',

  REACT_APP_JOIN_NOW_URL: env.REACT_APP_JOIN_NOW_URL || '',
  REACT_APP_NEWSLETTER_URL: "https://www.up0.net/functions/mailing_list.html",

  REACT_APP_SITE_NAME: env.REACT_APP_SITE_NAME || window.location.hostname,
  REACT_APP_SITE_TITLE: env.REACT_APP_SITE_TITLE || window.location.hostname,
  REACT_APP_SITE_DESCRIPTION: env.REACT_APP_SITE_DESCRIPTION || '',

  REACT_APP_SUPPORT_EMAIL: 'support@adultpaysitesupport.com',
  REACT_APP_BILLING_SUPPORT_SITE: 'https://epoch.com/billing_support',
  REACT_APP_DISPLAY_AFFILIATE_PROGRAM: env.REACT_APP_DISPLAY_AFFILIATE_PROGRAM || 'off',
  REACT_APP_WEBMASTERS_URL: 'https://www.nakedswordcash.com'
};

const WLConfigProvider = ({ children }) => {
  const [state, setState] = useState({
    wl_config: {},
    wl_playerConfig: {},
    wl_configLoading: true,
    variables: { ...envs }
  });

  useEffect(() => {
    // do not send requests on these pages
    const pathname = window.location.pathname;
    const noRequestOnUrls = [`/${ROUTES.diag}`];
    if (noRequestOnUrls.includes(pathname)) {
      return;
    }

    const promises = [
      getWhiteLabelConfig().catch(err => console.log(err)),
      getPlayerConfig().catch(err => console.log(err)),
    ];

    Promise.all(promises)
      .then((resp = []) => {
        const { data = {} } = resp[0]?.data || {};
        const { propertyPlayerConfig = {} } = resp[1]?.data?.data || {};
        const gandolfVariables = data?.variables || [];
        const variables = gandolfVariables.reduce((acc, curr) => {
          const vars = Object.assign(acc, { [curr.name]: curr.value }); // { name: value }
          return vars;
        }, {});
        const allVariables = { ...envs, ...variables };

        if (propertyPlayerConfig) {
          propertyPlayerConfig.progressBarColors = {
            primary: propertyPlayerConfig?.configValues?.playerColor || '#9f44b4',
            secondary: '#fff',
          };
        }

        setState((prevState) => ({
          ...prevState,
          wl_config: data || {},
          wl_playerConfig: propertyPlayerConfig || {},
          variables: allVariables
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => setState((prevState) => ({ ...prevState, wl_configLoading: false })));
  }, []);

  const showLoader = (val) => setState(prevState => ({ ...prevState, wl_configLoading: val }));

  return <WLConfigContext.Provider value={{ ...state, showLoader }}>{children}</WLConfigContext.Provider>;
};

const useWLconfigContext = () => {
  const context = useContext(WLConfigContext);
  if (context === undefined) {
    throw new Error('WLConfigContext must be used within a WLConfigProvider!');
  }
  return context;
};

export { WLConfigProvider, useWLconfigContext };
