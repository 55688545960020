import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, navigate } from '@reach/router';

import MemberPWReminder from '../MemberPWReminder/MemberPWReminder';
import { useAuthContext } from '../../context/AuthContext.js';
import { ROUTES } from '../../services/navigation/navigation.routes.js';

import './SignIn.scss';

export default function SignIn(props) {
  const { hideJoinLink = false, hideForgotPsw = false } = props;
  const { t } = useTranslation();
  const { login, showErrorLabel } = useAuthContext();

  const [state, setState] = useState({
    username: '',
    password: '',
    showPassword: false,
    showForgotPassword: false
  });

  const {
    username,
    password,
    showPassword,
    showForgotPassword
  } = state;

  const isDataValid = () => password && username;

  const onChange = (key, event) => {
    const { value } = event.target;
    setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const signInUserOnEnterIfValid = (event) => {
    if (event.key === 'Enter' && isDataValid()) {
      login(password, username);
    }
  };

  const renderPasswordInput = () => {
    return (
      <div className="InputWrapper d-flex align-center">
        <i className="fas fa-lock" />
        <input
          value={password}
          className="SignInInput"
          type={showPassword ? 'text' : 'password'}
          placeholder={t('SignIn.password')}
          onChange={e => onChange('password', e)}
          onKeyUp={signInUserOnEnterIfValid}
        />
        <i
          className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'} cursor-pointer`}
          title={`${showPassword ? 'Hide password' : 'Show password'}`}
          onClick={() => setState(prevState => ({ ...prevState, showPassword: !prevState.showPassword }))}
        />
      </div>
    );
  };

  const renderUsernameInput = () => {
    return (
      <div className="InputWrapper d-flex align-center">
        <i className="fas fa-user" />
        <input
          value={username}
          className="SignInInput"
          placeholder={t('SignIn.username')}
          onChange={e => onChange('username', e)}
          onKeyUp={signInUserOnEnterIfValid}
        />
      </div>
    );
  };

  const renderSignInButton = () => {
    let disabled = false;
    if (!isDataValid()) {
      disabled = true;
    }
    return (
      <button className='SignInButton' disabled={disabled} onClick={() => login(password, username)}>
        {t('SignIn.signIn')}
      </button>
    );
  };

  const renderForgotPassword = () => {
    if (hideForgotPsw) return null;

    return (
      <div className="ForgotPswContainer">
        <div className="IconsContainer cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showForgotPassword: !prevState.showForgotPassword }))}>
          {showForgotPassword ? (
            <span className="cursor-pointer fa fa-times" title={t('SignIn.closeForgot')} />
          ) : (
            <span>{t('SignIn.forgotPassword')}</span>
          )}
        </div>
        {showForgotPassword ? <MemberPWReminder hideJoinLink={hideJoinLink} /> : null}
      </div>
    );
  };

  const renderInvalidData = () => {
    return (
      <div className="InvalidDataContainer">
        <i className="fas fa-exclamation-triangle" />
        <div>{t('SignIn.invalidUsernamePasswordTitle')}</div>

        {!hideJoinLink && (
          <>
            <Link to={ROUTES.help}>
              {t('SignIn.invalidUsernamePasswordFAQ')}
            </Link>
            <br />
            <Link to={ROUTES.forgotpasswordpage}>
              {t('SignIn.invalidUsernamePasswordResetPasswordText')}
            </Link>
          </>
        )}
      </div>
    );
  };

  const renderMainView = () => {
    return (
      <div className="SignIn">
        {showErrorLabel ? renderInvalidData() : null}
        {renderUsernameInput()}
        {renderPasswordInput()}
        {renderSignInButton()}
        {!hideJoinLink && (
          <div className="d-flex justify-center">
            <span>{t('SignIn.notAMember')}</span>
            <span onClick={() => navigate('/join')} className="JoinHere">
              {t('SignIn.joinHere')}
            </span>
          </div>
        )}
        {renderForgotPassword()}
      </div>
    );
  };

  return renderMainView();
};